'use client'

import { MantineProvider } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { useEffect } from 'react'
import type { FC, ReactNode } from 'react'
import { useMemoryMonitor } from '@/hooks/useMemoryMonitor'
import { cssVariablesResolver, theme } from '@/theme'

const ClientThemeWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	useMemoryMonitor()

	const [colorScheme, _setColorScheme] = useLocalStorage<'light' | 'dark'>({
		key: 'mantine-color-scheme',
		defaultValue: 'light',
	})

	// Sync Tailwind’s dark mode
	useEffect(() => {
		if (typeof document !== 'undefined') {
			document.documentElement.classList.toggle('dark', colorScheme === 'dark')
		}
	}, [colorScheme])

	return (
		<MantineProvider
			theme={theme}
			cssVariablesResolver={cssVariablesResolver}
			forceColorScheme={colorScheme} // ✅ actively controls color scheme
		>
			{children}
		</MantineProvider>
	)
}

export default ClientThemeWrapper
