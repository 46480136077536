import { getVersion } from '@/lib/getVersion'
import { logger } from '@/utils/logger'
import { useEffect, useRef, useState } from 'react'

function useVersionChecker() {
	const [_currentVersion, setCurrentVersion] = useState<string | null>(null)
	const intervalIdRef = useRef<number | null>(null)
	const versionRef = useRef<string | null>(null)

	useEffect(() => {
		const checkVersion = async () => {
			logger.info('Checking for new deploy version...')
			try {
				const version = await getVersion()
				logger.info('Fetched version:', version)

				if (!versionRef.current) {
					versionRef.current = version
					setCurrentVersion(version)
					logger.info('Initial version set to:', version)
				} else if (versionRef.current !== version) {
					logger.info(
						`Version checker found upgrade from ${versionRef.current} to ${version} and is reloading`,
					)
					window.location.reload()
				}
			} catch (error) {
				logger.error('Error checking app version:', error)
			}
		}

		checkVersion()
		intervalIdRef.current = window.setInterval(checkVersion, 60 * 1000) // 1 minutes
		logger.info('Version checker interval set up')

		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
				logger.info('Version checker interval cleared')
			}
		}
	}, []) // Empty dependency array

	return null
}

export default useVersionChecker
