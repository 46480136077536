'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
export const queryClientInstance = new QueryClient({
	defaultOptions: {
		queries: {
			// With SSR, we usually want to set some default staleTime
			// above 0 to avoid refetching immediately on the client
			// TODO change this back when I do mutations and prefetching
			staleTime: 60 * 1000, // 1 minute
		},
	},
})
export const ReactQueryClientProvider = ({ children }: { children: React.ReactNode }) => {
	return <QueryClientProvider client={queryClientInstance}>{children}</QueryClientProvider>
}
